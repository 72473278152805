.status {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(157, 182, 104, 1) 100%
  );
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 80%;
  position: absolute;
  right: 0;
  flex-direction: column;
  overflow: auto;
}

.bttns_inline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 15%;
}

.bttns_inline .btn {
  margin: 5px;
  text-transform: uppercase;
}

.mb-3.input-group {
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
}

.date-picker {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 15%;
}

.nice-dates .mb-3.form-floating {
  border-color: transparent;
  margin: 2px;
  border-radius: 2px;
  outline: none;
  color: #7c7a7a !important;
}

.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  max-width: 400px !important;
  transition: none;
}

.status_start_page {
  display: flex;
  width: 80%;
}

.status .table-responsive th {
  font-size: 0.8rem;
  text-align: left;
}

.status .table-bttns.btn-group button {
  margin: 0;
}

@media screen and (max-width: 960px) {
  .status {
    width: 100%;
  }
  .nice-dates-popover {
    max-width: 300px !important;
  }
}
