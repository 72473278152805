.table-responsive {
  cursor: pointer;
  width: 80%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
}

.table-bttns button {
  margin: 0 5%;
  text-transform: uppercase;
}

.fa-caret-down,
.fa-caret-up {
  padding: 2px;
}

thead tr {
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
  border-top: none !important;
}

@media screen and (max-width: 960px) {
  .table-bttns button {
    margin: 0;
  }
}
