.navbar {
  height: 8vh;
  background-color: #9db668;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu_bars {
  position: absolute;
  left: 0;
  margin-left: 1rem;
}

.fa-bars {
  color: #333333;
}

.fa-sign-out-alt {
  color: #9db668;
}
.nav_menu {
  background: #fff;
  border-right: 1px solid rgb(157, 182, 104, 0.5);
  width: 30vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav_menu.active {
  left: 0;
  transition: 350ms;
  width: 20%;
  z-index: 3;
  background-color: #fff;
}

.navbar-toggle {
  background-color: #fff;
  width: 100%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
}

.navbar-toggle a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.navbar-toggle img {
  height: 80%;
}

.nav_text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  list-style: none;
  height: 5vh;
  text-transform: uppercase;
}

.nav_text .active {
  color: #9db668;
  border-right: 1px solid #9db668;
  border-width: medium;
  height: 150%;
}

.nav_text a {
  text-decoration: none;
  color: #333333;
  font-size: 1.1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.nav_text a:hover {
  color: #9db668;
  border-right: 1px solid #9db668;
  border-width: medium;
  height: 150%;
  transition: 100ms;
}

.nav_menu_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 2%;
  width: 100%;
}

.nav_menu_bottom .nav_text a:hover {
  border-right: none;
}

.nav_menu_items {
  width: 100%;
  padding-left: 0px;
}

.nav_language {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  list-style: none;
  height: 5vh;
  text-transform: uppercase;
}

.nav_language div {
  width: 100%;
  margin: 3px;
}

.nav_language_div {
  cursor: pointer;
}

.nav_language_div.active {
  color: #9db668;
  height: 150%;
}

.nav_language_div:hover {
  color: #9db668;
  height: 150%;
  transition: 100ms;
}

@media screen and (max-width: 960px) {
  .nav_menu.active {
    width: 50%;
  }
}
