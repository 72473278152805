@import url("https://fonts.googleapis.com/css2?family=PT+Sans&family=Arial&family=Helvetica&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans,Helvetica", sans-serif;
}

@import "components/login";
@import "components/home";
@import "components/navbar";
@import "components/table";
@import "components/cutting";
@import "components/species";
@import "components/status";
@import "components/store";
@import "components/user";
@import "components/sales";
@import "components/services";
@import "components/report";
@import "components/orders";
@import "components/calendar";
//    font-family: "Unica One", Arial, Helvetica, sans-serif;

//zelena #9db668
//crna #333333
//bela #fff
//siva #dddddd
