.user {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(157, 182, 104, 1) 100%
  );
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 80%;
  position: absolute;
  right: 0;
  flex-direction: column;
  overflow: auto;
}

.user .bttns_inline {
  justify-content: space-between;
  width: 80%;
}

.user .col-form-label {
  font-weight: bolder;
  text-transform: uppercase;
}

#change_pass_bttn {
  text-transform: uppercase;
}

.user form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 960px) {
  .user {
    width: 100%;
  }
}
