.home {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(157, 182, 104, 1) 100%
  );
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 80%;
  position: absolute;
  right: 0;
  flex-direction: column;
  overflow: auto;
}

.home .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/logo/wunderbaum-logo.png") center center/contain
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  opacity: 0.4;
}

@media screen and (max-width: 960px) {
  .home {
    width: 100%;
  }
}
