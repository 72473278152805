.calendar {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(157, 182, 104, 1) 100%
  );
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 80%;
  position: absolute;
  right: 0;
  flex-direction: column;
  overflow: auto;
}

.calendar a {
  color: black;
  text-decoration: none;
}

.calendar .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  background: white;
  width: 80%;
}

.calendar .fc-prev-button.fc-button.fc-button-primary,
.calendar .fc-next-button.fc-button.fc-button-primary {
  background-color: transparent;
  color: black;
  border: none;
  outline: none;
}

.calendar .fc-today-button.fc-button.fc-button-primary {
  margin: 5px;
  border-radius: 5px !important;
  padding: 6px 10px;
}

.calendar .fc.fc-button-group {
  background-color: transparent;
  color: black;
  outline: none;
  border: none;
}

@media screen and (max-width: 960px) {
  .calendar {
    width: 100%;
  }
}
