.login {
  background: url("../../assets/images/wunderbaum-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

//zelena #9db668
//crna #333333
//bela #fff
//siva #dddddd

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgba(51, 51, 51, 0.8);
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  width: 40%;
  padding: 50px 20px;
}

.login-input {
  border-radius: 10px;
  width: 100%;
  height: 10%;
  padding: 10px;
  margin: 10px;
  color: #595959;
  font-size: 1rem;
  outline: none;
}

.login-form-input::placeholder {
  color: #595959;
  font-size: 1rem;
}

.login-bttn {
  border-radius: 10px;
  width: 100%;
  height: 10%;
  padding: 10px;
  margin: 10px;
  color: #fff;
  background-color: #9db668;
  border: 1px solid #9db668;
  font-size: 1rem;
  outline: none;
}

.login-bttn:hover {
  cursor: pointer;
  transition: all 0.3s ease-out;
  background-color: #293809;
  border: 1px solid #293809;
  font-size: 1rem;
  color: #fff;
  transition: 250ms;
}

.login .alert {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 720px) {
  .login-container {
    width: 80%;
  }

  .login {
    background-size: cover;
  }
}
